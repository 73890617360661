import React, {useEffect, useState} from 'react';
import TitleSection from "../../../../../../../components/Atomes/Title/TitleSection";
import InputLabelErrorMessage from "../../../../../../../components/Atomes/Formik/InputLabelErrorMessage";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import ArchitectService from "../../../../../../../services/ArchitectService";
import {toast} from "react-toastify";
import {useFormikContext} from "formik";
import ButtonAddElementForm from "../../../../../../../components/Atomes/Buttons/ButtonAddElementForm";


const FormDevisArch = ({setShowPopUp, requestArchForm, project}) => {


	const [isLoadingArchitect, setIsLoadingArchitect] = useState(true);

	const {values} = useFormikContext()
	//Get all architecte collection
	const [architectCollection, setArchitectCollection] = useState([]);
	const getArchitectCollection = async () => {
		setIsLoadingArchitect(true)
		const request = await ArchitectService.getArchitectCollection({ 'pagination': 'false' })
		if (request.request.status === 200) {
			const data = request.data['hydra:member']
			data.map((item) => {
				item.label = item.firstname + " " + item.lastname
				item.idUrl = "/api/users/" + item.id
			})
			setArchitectCollection(data)
			setIsLoadingArchitect(false)
		} else {
			toast.error('Un erreur est servenue lors de la récupération de la liste des architect')
		}
	}

	/*if an architect has been added we relaunch the get function*/
	useEffect(() => {
		if (requestArchForm === "success add") {
			getArchitectCollection()
		}
	}, [requestArchForm]);

	useEffect(() => {
		getArchitectCollection()
	}, []);

	const {setFieldValue} = useFormikContext()

	/*Initial Values*/
	useEffect(() => {
		if (project.arch) {
			setFieldValue('archIri', `/api/users/${project.arch.id}`)
		}

		if(project && !project.arch && (project.quotes.length > 0)) {
			setFieldValue('archIri', 'null')
		}
	}, [project]);

	return (<div className={"form-devis-container-state-meet"}>
		<div className={"form-section-title"}>
			<TitleSection title={"Gestionnaire du projet"}/>
		</div>
		<div className={"form-devis-container-information-agence-start"}>
			<div className={"form-row-element form-row-single-element form-row-element-loading"}>
				{isLoadingArchitect ?
					<Skeleton count={1} height={50}/> :
					<>
						<InputLabelErrorMessage devis={true} canBeNull={true} placeholder={"Gestionnaire projet"}
						                        label={"Affilier un professionnel au projet"} name={"archIri"} type={"select"}
						                        data={architectCollection} keyValue={"idUrl"} keyLabel={"label"}/>
					</>
				}
			</div>
			<div className={"form-row-element form-row-single-elements"}>
				<div>
				</div>
				<ButtonAddElementForm onClick={(e) => setShowPopUp(true)} title={"Ajouter un gestionnaire"}/>
			</div>
		</div>
	</div>);
};

export default FormDevisArch;
